import React from 'react';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import { translate, TranslationFunction } from 'yoshi-flow-editor-runtime';
import TextField from '../TextField';
import styles from './DineInDetails.scss';

interface DineInDetailsProps {
  description: string;
  tableNumber: string;
  label: string;
  onChangeTableNumber: (num: string) => void;
  shouldShowError: boolean;
  t: TranslationFunction;
}
export const DineInDetails = translate()((props: DineInDetailsProps) => {
  const { t, tableNumber, shouldShowError, description, label, onChangeTableNumber } = props;

  return (
    <div className={styles.wrapperDinein}>
      <Text typography="p2-m" data-hook={dataHooks.dispatchSettingDineInDescription} className={styles.description}>
        {description}
      </Text>
      <Text typography="p2-m" data-hook={dataHooks.dispatchSettingDineInLabel} className={styles.label}>
        {label + '*'}
      </Text>
      <TextField
        data-hook={dataHooks.dispatchSettingDineInTableNumber}
        value={tableNumber || ''}
        onChange={(e) => onChangeTableNumber(e.target.value || '')}
        className={styles.inputField}
        error={shouldShowError}
        errorMessage={shouldShowError ? t('delivery_modal_PickupOption_Curbside_FieldLabel_ErrorMessage') : undefined}
        maxLength={40}
        showCharCount
      />
    </div>
  );
});
