import { AppState } from '../../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import { openModal } from '../../../../../state/session/session.actions';
import RestaurantTakeoutDetails from './RestaurantTakeoutDetails';
import { connect } from 'react-redux';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState, ownProps: any) {
  return {
    ...ownProps,
    isLocationPicked: state.session.isLocationPicked,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openModal,
    },
    dispatch,
  );
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(RestaurantTakeoutDetails));
