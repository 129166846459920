import ChooseLocationDropdown from './ChooseLocationDropdown';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';

function mapStateToProps(state: AppState, ...ownProps: any) {
  const { priceComponents } = cartSummarySelector(state);

  return {
    totalOrderPrice: priceComponents.total,
    dispatchTime: state.checkout.dispatch.time,
    selectedAddressOption: state.addressForm.selectedAddressOption,
    isLoading: state.session.isLoadingLocationsSettingsFromServer,
    locations: state.session.locations,
    currentLocationId: state.session.restaurant.currentLocationId,
    isLocationPicked: state.session.isLocationPicked,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ChooseLocationDropdown);
