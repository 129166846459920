import React from 'react';
import Text from '../../core-components/Text';
import styles from './AddressInformation.scss';
import dataHooks from '../../data-hooks';
import { Address, DispatchInfo, VirtualDispatchType } from '@wix/restaurants-client-logic';
import { TranslationFunction } from 'i18next';
import { translate } from 'yoshi-flow-editor-runtime';
import { getAsapText } from '../DispatchTimeSelector/DispatchTimeSelector.helper';
import { DineInInfo } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';

export interface AddressInformationSummaryProps {
  address: Address;
  dispatchType: VirtualDispatchType;
  dispatchTime: number;
  timezone: string;
  t: TranslationFunction;
  deliveryInfos: DispatchInfo[];
  idealDeliveryArea?: DispatchInfo;
  dineInInfo?: DineInInfo;
  tableNumber?: string;
  locale: string;
  isCurbsideToggledOn: boolean;
}

function getAddressDetailsLine(address: Address) {
  const result: string[] = [];

  if (address.apt) {
    result.push(`Apt: ${address.apt}`);
  }

  if (address.floor) {
    result.push(`Floor: ${address.floor}`);
  }

  if (address.entrance) {
    result.push(`Entrance: ${address.entrance}`);
  }

  return result.join(', ');
}

function getText(
  t: TranslationFunction,
  dispatchType: VirtualDispatchType,
  timezone: string,
  locale: string,
  deliveryInfos: DispatchInfo[],
  dispatchTime?: number,
  idealDeliveryArea?: DispatchInfo,
) {
  const timing = dispatchTime
    ? new Intl.DateTimeFormat(locale.split('_')[0], {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone,
      }).format(dispatchTime)
    : getAsapText({ deliveryInfos, timezone, dispatchType, t, idealDeliveryArea, locale });

  const label =
    dispatchType === 'delivery'
      ? t('order_settings_modal_delivery_time_label')
      : t('order_settings_modal_pickup_time_label');

  return `${label}: ${timing}`;
}

const pickupText = (isCurbsideToggledOn: boolean, t: TranslationFunction) =>
  isCurbsideToggledOn
    ? t('Checkout_main_deliverymethod_confirmed_CurbsidePickup_Label')
    : t('checkout_main_delivery_method_pickup_label');

const AddressInformationSummary: React.FC<AddressInformationSummaryProps> = ({
  address,
  dispatchType,
  dispatchTime,
  timezone,
  locale,
  t,
  deliveryInfos,
  idealDeliveryArea,
  dineInInfo,
  tableNumber,
  isCurbsideToggledOn,
}) => {
  const addressDetailsLine = getAddressDetailsLine(address);

  const getDispatchLabel = () => {
    switch (dispatchType) {
      case 'delivery':
        return t('checkout_main_delivery_method_delivery_label');
      case 'takeout':
        return pickupText(isCurbsideToggledOn, t);
      case 'dine-in':
        return t('Checkout_main_deliverymethod_confirmed_DineIn_Label');
      case 'premises':
      default:
        return '';
    }
  };

  return (
    <div className={styles.summaryWrapper}>
      <Text
        typography="p2-m-secondary"
        className={styles.summaryLine}
        data-hook={dataHooks.addressInformationSummaryLine}
      >
        {getDispatchLabel()}
      </Text>
      {dispatchType === 'dine-in' ? (
        <Text
          typography="p2-m-secondary"
          className={styles.summaryLine}
          data-hook={dataHooks.addressInformationSummaryLine}
        >
          {dineInInfo && `${dineInInfo.label} ${tableNumber}`}
        </Text>
      ) : (
        <React.Fragment>
          {address.label && (
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.addressInformationSummaryLine}
            >
              {address.label}
            </Text>
          )}
          <Text
            typography="p2-m-secondary"
            className={styles.summaryLine}
            data-hook={dataHooks.addressInformationSummaryLine}
          >
            {address.formatted}
          </Text>
          {addressDetailsLine && (
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.addressInformationSummaryLine}
            >
              {addressDetailsLine}
            </Text>
          )}
          {address.addressLine2 && (
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.addressInformationSummaryLine}
            >
              {address.addressLine2}
            </Text>
          )}
          {address.comment && (
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.addressInformationSummaryLine}
            >
              "{address.comment}"
            </Text>
          )}
          <Text
            typography="p2-m-secondary"
            className={styles.summaryLine}
            data-hook={dataHooks.addressInformationSummaryLine}
          >
            {getText(t, dispatchType, timezone, locale, deliveryInfos, dispatchTime, idealDeliveryArea)}
          </Text>
        </React.Fragment>
      )}
    </div>
  );
};

AddressInformationSummary.displayName = 'AddressInformationSummary';

export default translate()(AddressInformationSummary);
