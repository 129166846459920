import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { closeModal, navigate, openModal } from '../../../../state/session/session.actions';
import { bindActionCreators, Dispatch } from 'redux';
import {
  addOrderCoupon,
  changeOrderItemCount,
  removeOrderCoupon,
  removeOrderItem,
  setOrderComment,
} from '../../../../state/cart/cart.actions';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { hasCouponsSelector } from '../../../../state/selectors/hasCouponsSelector';
import Cart from './Cart';
import { translate } from 'yoshi-flow-editor-runtime';
import { loyaltyProgramSelector } from '../../../../state/selectors/loyaltyProgramSelector';
import {
  resetLoyaltyPointsToDefaultValue,
  setLoyaltyPointsToRedeem,
} from '../../../../state/checkout/checkout.actions';
import { getDisplayableRewards } from './Cart.helper';
import { isLoyaltyDiscount } from '@wix/restaurants-client-logic';

function mapStateToProps(state: AppState, ownProps: any) {
  const {
    displayableDiscounts,
    displayablePriceComponents,
    displayableOrderItems,
    totalOrderPrice,
    maxRedeemablePoints,
    redeemPoints,
  } = cartSummarySelector(state);

  const { isActive, pointsRequired, currencyValue } = loyaltyProgramSelector(state);
  const { currency, locale } = state.session.restaurant;

  const displayableRewards = getDisplayableRewards({
    isActive,
    pointsRequired,
    maxRedeemablePoints,
    locale,
    currency,
    currencyValue,
  });

  return {
    comment: state.cart.comment,
    displayableDiscounts,
    displayablePriceComponents,
    displayableOrderItems,
    couponCode: (state.cart.coupon?.type === 'success' && state.cart.coupon.code) || undefined,
    couponError: (state.cart.coupon?.type === 'failure' && state.cart.coupon.message) || undefined,
    hasCoupons: hasCouponsSelector(state),
    dispatchType: state.checkout.dispatch.type,
    isMobile: state.platformParams.isMobile,
    isRTL: state.platformParams.isRTL,
    selectedAddressOption: state.addressForm.selectedAddressOption,
    totalOrderPrice,
    restaurant: state.session.restaurant,
    displayableRewards,
    redeemPoints,
    loyaltyDiscountIconUrl: state.session.loyaltyProgram?.pointsIcon?.url,
    shouldDisplayLoyaltyButton:
      Boolean(state.session.menu.chargesV2.find(isLoyaltyDiscount)) && !displayableDiscounts.find((dd) => dd.isLoyalty),
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      navigate,
      closeModal,
      setOrderComment,
      addOrderCoupon,
      removeOrderCoupon,
      removeOrderItem,
      changeOrderItemCount,
      openModal,
      setLoyaltyPointsToRedeem,
      resetLoyaltyPointsToDefaultValue,
    },
    dispatch,
  );
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Cart));
