import React, { useCallback } from 'react';
import styles from './MenuItemView.scss';
import Text from '../../core-components/Text';
import LabelsView from '../LabelsView';
import dataHooks from '../../data-hooks';
import { MenuDisplayOptions } from '@wix/restaurants-client-logic';
import PriceView from '../PriceView';
import { ThumbnailImage, LoadingBehaviorOptions } from 'wix-ui-tpa/ThumbnailImage';

export interface MenuItemViewProps {
  id: string;
  title?: string;
  description?: string;
  price?: string;
  minPrice?: string;
  maxPrice?: string;
  labels?: string[];
  image?: string;
  discount?: string;
  error?: string;
  onClick?: (id: string) => void;
  isMobile?: boolean;
  className?: string;
  layout?: 'side-by-side' | 'card';
  menuDisplayOptions?: MenuDisplayOptions;
  role?: string;
  currency: string;
}

function getFixedDimension(isMobile: boolean | undefined, isCardLayout: boolean) {
  if (isMobile) {
    if (isCardLayout) {
      return 178;
    } else {
      return 68;
    }
  } else {
    if (isCardLayout) {
      return 190 * 1.5;
    } else {
      return 178;
    }
  }
}

const MenuItemView: React.FC<MenuItemViewProps> = ({
  id,
  title,
  description,
  price,
  minPrice,
  labels,
  image,
  discount,
  error = '',
  onClick,
  isMobile,
  className,
  layout = 'side-by-side',
  menuDisplayOptions,
  role,
  currency,
}) => {
  const handleClick = useCallback(
    (e) => {
      // If triggered by mouse click, blur element to override bug on react-modal close
      if (e.nativeEvent.detail) {
        e.currentTarget.blur();
      }

      if (onClick) {
        onClick(id);
      }
    },
    [id, onClick],
  );

  const isCardLayout = layout === 'card';
  const fixedDimension = getFixedDimension(isMobile, isCardLayout);
  const showDishImage = !menuDisplayOptions?.hideDishImages;
  const showDescription = !menuDisplayOptions?.hideDishDescriptions;
  const showLabels = !menuDisplayOptions?.hideMenuItemLabels;

  // Deliberately using an empty string as alt text.
  // In this particular case adding the dish title/description will not have any effect,
  // since this data is shown directly under the image, and we do not want screen reader to read it twice.
  const alt = '';

  const menuImage = showDishImage && (
    <div data-hook={dataHooks.itemViewImage} className={styles.imageWrapper}>
      {image ? (
        <ThumbnailImage
          src={image}
          {...(isCardLayout
            ? { aspectRatio: 1.5, height: fixedDimension }
            : { width: fixedDimension, height: fixedDimension })}
          alt={alt}
          loadingBehavior={LoadingBehaviorOptions.blur}
          fluid
        />
      ) : (
        <div className={styles.imagePlaceholder} />
      )}
      {error && (
        <div className={styles.mask}>
          <Text typography="p2-m-colorless" className={styles.maskContent}>
            {error}
          </Text>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={`${styles.wrapper} ${isMobile && styles.mobile} ${styles[layout]} ${className}`}
      role={role}
      data-hook={dataHooks.itemView(id)}
      onClick={handleClick}
    >
      {layout === 'card' && menuImage}

      <div className={`${styles.contentWrapper} ${styles[layout]}`}>
        <Text typography="header-xxs" className={styles.title} data-hook={dataHooks.itemViewTitle} tagName="button">
          {title}
        </Text>
        {showDescription && description && (
          <Text typography="p2-s" className={styles.description} data-hook={dataHooks.itemViewDescription}>
            {description}
          </Text>
        )}
        <div className={styles.spacer} />
        {showLabels && <LabelsView labels={labels || []} className={styles.labels} disabled={Boolean(error)} />}
        <PriceView
          typography={error ? 'p2-m-secondary' : 'p2-m'}
          className={styles.price}
          data-hook={dataHooks.itemViewPrice}
          price={minPrice || price}
          suffix={error}
          currency={currency}
        />
      </div>

      {layout === 'side-by-side' && menuImage}

      {discount && (
        <Text typography="p2-s-inverse" className={styles.discount}>
          {discount}
        </Text>
      )}
    </div>
  );
};

MenuItemView.displayName = 'MenuItemView';

export default MenuItemView;
