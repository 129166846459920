import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import dataHooks from '../../data-hooks';
import { CloseModalPayload, OpenModalPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import SubMenuView from './SubMenuView';
import styles from './MenuView.scss';
import MenuViewEmptyState from '../MenuViewEmptyState';
import StickyNavigationHeader from '../StickyNavigationHeader';
import FloatingCartButton from '../FloatingCartButton';
import NotificationBar from '../NotificationBar';
import DispatchSummary from '../DispatchSummary';
import MenuPicker from '../MenuPicker';
import { BusinessNotifications, DisplayableMenu, MenuDisplayOptions, Restaurant } from '@wix/restaurants-client-logic';
import NoBusinessAddressNotification from '../NoBusinessAddressNotification';
import { scrollToMenuOrSection } from '../Scroller/scrollingUtils';
import { useExperiments } from '@wix/wix-experiments-react';
import LocationHeader from '../LocationHeader';
import { ClickOnMenuItemPayload } from '../../../../state/cart/cart.actions.types';

export interface MenuViewProps {
  displayableMenu: DisplayableMenu;
  orderItemsCount: number;
  isMobile?: boolean;
  layout?: 'side-by-side' | 'card';
  menuDisplayOptions?: MenuDisplayOptions;
  isRTL?: boolean;
  isCartModalOpen?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  closeModal: (payload: CloseModalPayload) => void;
  notification?: BusinessNotifications;
  restaurant: Restaurant;
  clickOnMenuItem: (payload: ClickOnMenuItemPayload) => void;
}

function convertSlugToEntityId(displayableMenu: DisplayableMenu, menuSlug: string, sectionSlug?: string) {
  const displayableSubMenu = displayableMenu.find((sm) => sm.slug === menuSlug);
  const displayableSection = displayableSubMenu?.sections.find((s) => s.slug === `${menuSlug}/${sectionSlug}`);
  return displayableSection?.id || displayableSubMenu?.id;
}

const MenuView: React.FC<MenuViewProps> = ({
  displayableMenu,
  orderItemsCount,
  isMobile,
  layout,
  menuDisplayOptions,
  isRTL,
  isCartModalOpen,
  notification,
  openModal,
  closeModal,
  restaurant,
  clickOnMenuItem,
}) => {
  const experiments = useExperiments();
  const match = useRouteMatch<{ subRoute: string; sectionSlug: string }>();
  const hasMenus = displayableMenu.length > 0;
  const isMLEnabled = experiments.experiments.enabled('specs.restaurants.olo-client-ml');

  useEffect(() => {
    if (match.params.subRoute === 'cart' && !isCartModalOpen) {
      openModal({ modal: Modals.CART_MODAL, redirectOnClose: '/' });
    } else if (match.params.subRoute !== 'cart' && isCartModalOpen) {
      closeModal({ modal: Modals.CART_MODAL });
    }

    if (match.params.subRoute && match.params.subRoute !== 'cart') {
      const entityId = convertSlugToEntityId(displayableMenu, match.params.subRoute, match.params.sectionSlug);
      if (entityId) {
        scrollToMenuOrSection(entityId, isMobile);
      }
    }
  }, [
    isCartModalOpen,
    openModal,
    closeModal,
    match.params.subRoute,
    match.params.sectionSlug,
    isMobile,
    displayableMenu,
  ]);
  const shouldRenderDispatchSummary = notification !== BusinessNotifications.RestaurantClosed;

  return (
    <div id={dataHooks.menuViewWrapper} data-hook={dataHooks.menuViewWrapper}>
      <NoBusinessAddressNotification />
      <NotificationBar />
      {shouldRenderDispatchSummary ? <DispatchSummary /> : null}
      {isMLEnabled ? (
        <LocationHeader
          name={restaurant.locationName}
          locale={restaurant.locale}
          address={restaurant.address.formatted}
        />
      ) : null}
      {!isMobile && <StickyNavigationHeader />}
      {isMobile && displayableMenu.length > 1 && <MenuPicker menus={displayableMenu} />}
      <div className={styles.wrapper} data-hook={dataHooks.menuView}>
        {!hasMenus && <MenuViewEmptyState />}

        {displayableMenu.map((displayableSubMenu) => (
          <SubMenuView
            clickOnMenuItem={clickOnMenuItem}
            key={displayableSubMenu.id}
            displayableSubMenu={displayableSubMenu}
            isMobile={isMobile}
            layout={layout}
            menuDisplayOptions={menuDisplayOptions}
            isRTL={isRTL}
          />
        ))}
      </div>
      {isMobile && orderItemsCount > 0 && <FloatingCartButton />}
    </div>
  );
};

MenuView.displayName = 'MenuView';

export default MenuView;
