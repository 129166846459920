import React from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import styles from './ChangeLocationModal.scss';
import Button from '../Button';
import dataHooks from '../../data-hooks';
import { TranslationFunction } from 'i18next';
import { ChangeLocationPayload } from '../../../../state/session/session.actions.types';
import { PRIORITY } from 'wix-ui-tpa/Button';

export interface ChangeLocationModalProps {
  onRequestClose: () => void;
  changeLocation: (payload: ChangeLocationPayload) => void;
  t: TranslationFunction;
  locationId: string;
  isLoadingLocationFromServer: boolean;
}

const ChangeLocationModal: React.FC<ChangeLocationModalProps> = ({
  onRequestClose,
  t,
  changeLocation,
  locationId,
  isLoadingLocationFromServer,
}) => {
  function onOkClick() {
    changeLocation({ locationId });
  }

  function onCancelClick() {
    onRequestClose();
  }

  const header = (
    <Text className={styles.header} typography="header-m" data-hook={dataHooks.changeLocationModalTitle}>
      {t('error_modal_change_location_title')}
    </Text>
  );

  const footer = (
    <div className={styles.buttonsWrapper}>
      <Button
        priority={PRIORITY.secondary}
        upgrade
        data-hook={dataHooks.changeLocationModalCancel}
        onClick={onCancelClick}
      >
        {t('error_modal_change_location_cancel')}
      </Button>
      <Button
        priority={PRIORITY.primary}
        loading={isLoadingLocationFromServer}
        className={styles.okButton}
        upgrade
        data-hook={dataHooks.changeLocationModalOK}
        onClick={onOkClick}
      >
        {t('error_modal_change_location_back_to_menu')}
      </Button>
    </div>
  );

  return (
    <ModalLayoutBasic
      header={header}
      onCloseClick={onCancelClick}
      data-hook={dataHooks.changeLocationModal}
      footer={footer}
    >
      <div className={styles.wrapper}>
        <Text typography="p2-m">{t('error_modal_change_location_description')}</Text>
      </div>
    </ModalLayoutBasic>
  );
};

ChangeLocationModal.displayName = 'ChangeLocationModal';

export default ChangeLocationModal;
