import React from 'react';
import { scroller } from 'react-scroll';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { getDisplayablePrice, Payment, Tip, YearMonth } from '@wix/restaurants-client-logic';
import { translate, useBi } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import { CheckoutStep } from '../../../../core/types/Checkout';
import dataHooks from '../../data-hooks';
import { calculateTopValue } from '../Scroller/scrollingUtils';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import Cashier from '../Cashier';
import Scroller from '../Scroller';
import Text from '../../core-components/Text';
import {
  SetCashierPaymentPayload,
  SetPendingCashierPaymentPayload,
  SetTipPayload,
} from '../../../../state/checkout/checkout.actions.types';
import { ApiUnavailable, NotValidError, validateAndInitializePayment } from '../../../../core/logic/cashierLogic';
import { InitializePaymentResult } from '@wix/cashier-payments-widget/dist/src/types/InitializePaymentResult';
import TipPicker from '../TipPicker';
import CheckoutPaymentsSummary from '../CheckoutPaymentsSummary';
import Button from '../Button';
import styles from './CheckoutPayments.scss';

function convertToPayload({ detailsId, cardPublicData }: InitializePaymentResult) {
  const result: SetCashierPaymentPayload = { paymentDetailsId: detailsId };

  if (cardPublicData) {
    result.creditCard = {
      network: cardPublicData.type,
      expiration: cardPublicData.expiration as YearMonth,
      lastDigits: cardPublicData.lastFourDigits || '',
    };
  }

  return result;
}

function convertCashierErrorToString(errorResult: NotValidError | ApiUnavailable) {
  let cashierStringError = errorResult.error;
  if ('invalidFields' in errorResult) {
    cashierStringError += `: ${errorResult.invalidFields.join(',')}`;
  }
  return cashierStringError;
}

export interface CheckoutPaymentsProps {
  done?: boolean;
  collapsed?: boolean;
  index?: string;
  shouldDisplayTip: boolean;
  payments: Payment[];
  step: CheckoutStep;
  tip?: Tip;
  isLoading?: boolean;
  currency: string;
  locale: string;
  totalOrderPrice: number;
  tipCharge: number;
  paymentMethod: string | undefined;
  onEdit: () => void;
  onSubmit: () => void;
  setTip: (payload: SetTipPayload) => void;
  setCashierPayment: (payload: SetCashierPaymentPayload) => void;
  setPendingCashierPayment: (payload: SetPendingCashierPaymentPayload) => void;
  setCashierPaymentDone: () => void;
  t: TranslationFunction;
  describedby?: string;
}

const CheckoutPayments: React.FC<CheckoutPaymentsProps> = ({
  done,
  collapsed,
  index = '0',
  shouldDisplayTip,
  payments,
  step,
  tip,
  isLoading,
  currency,
  locale,
  totalOrderPrice,
  tipCharge,
  paymentMethod,
  onEdit,
  onSubmit,
  setTip,
  setCashierPayment,
  setPendingCashierPayment,
  setCashierPaymentDone,
  t,
  describedby,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const biLogger = useBi();

  if (isSubmitting && step !== 'payments') {
    setIsSubmitting(false);
  }

  // There's an issue with the Cashier widget not updating addresses, so we must re-render it on each address update
  const renderCashier = step !== 'address-information';

  const titleId = `${dataHooks.checkoutPayments}-title`;
  const amount = getDisplayablePrice(totalOrderPrice, locale, currency).match(/[\d.,]+/g)?.[0] || '';

  return (
    <Scroller name="checkout-payments" condition={!collapsed && !done}>
      <div
        className={styles.wrapper}
        data-hook={dataHooks.checkoutPayments}
        aria-labelledby={titleId}
        aria-describedby={describedby}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_payments_title')}
          done={done}
          collapsed={collapsed}
          index={index}
          onEdit={onEdit}
          editButtonDataHook={dataHooks.checkoutSummaryLineEditPayment}
          titleId={titleId}
        />
        {renderCashier && (
          <Cashier
            hidden={done || collapsed}
            paymentMethodChanged={(paymentMethodId, paymentMethodTitle) =>
              setPendingCashierPayment({ payment: { paymentMethod: paymentMethodId }, paymentMethodTitle })
            }
            amount={amount}
          />
        )}

        {!done && !collapsed && <div className={styles.padder} />}

        {shouldDisplayTip && !done && !collapsed && (
          <TipPicker tip={tip} onTipChange={setTip} currency={currency} locale={locale} />
        )}

        {!done && !collapsed && (
          <Button
            upgrade
            fullWidth
            priority={PRIORITY.primary}
            className={styles.button}
            data-hook={dataHooks.checkoutPaymentsContinue}
            onClick={() => {
              setIsSubmitting(true);
              validateAndInitializePayment(
                (initializePaymentResult) => {
                  biLogger.paymentsInformationContinue({
                    currency,
                    paymentType: paymentMethod || '',
                    totalTip: Math.round(tipCharge),
                    totalOrderValue: Math.round(totalOrderPrice),
                  });
                  setCashierPayment(convertToPayload(initializePaymentResult));
                  onSubmit();
                },
                (error) => {
                  biLogger.paymentsInformationContinueValidationError({
                    errorReason: convertCashierErrorToString(error),
                  });
                  setCashierPaymentDone();
                  setIsSubmitting(false);
                  scroller.scrollTo('checkout-payments', {
                    smooth: 'easeInOutCubic',
                    duration: 200,
                    offset: -calculateTopValue(0) - 36,
                  });
                },
              );
            }}
            loading={isSubmitting || isLoading}
          >
            <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
          </Button>
        )}

        {done && <CheckoutPaymentsSummary payments={payments} />}
      </div>
    </Scroller>
  );
};

CheckoutPayments.displayName = 'CheckoutPayments';

export default translate()(CheckoutPayments);
