import React from 'react';
import styles from '../AddressInformation.scss';
import Text from '../../../core-components/Text';
import AddressInput from '../../AddressInput';
import dataHooks from '../../../data-hooks';
import AddressInformationInput from '../AddressInformationInput';
import CommentInput from '../../CommentInput';
import { DeliveryFormField } from '../../../../../state/addressInformationForm/addressForm.reducer';
import {
  SetAddressInputValuePayload,
  SetDeliveryAddressFieldPayload,
  SetErrorVisibilityPayload,
  SetSelectedAddressPayload,
} from '../../../../../state/addressInformationForm/addressForm.actions.types';
import { TranslationFunction } from 'i18next';
import { Address, Restaurant, ValidateAddressReason } from '@wix/restaurants-client-logic';
import { Element } from 'react-scroll';
import { getValidateAddressReasonText } from '../AddressInformation.helper';
import classNames from 'classnames';
import { Modals } from '../../../../../core/constants';
import { OpenModalPayload } from '../../../../../state/session/session.actions.types';
import ChooseLocationDropdown from '../../ChooseLocationDropdown';

export interface AddressInformationDeliveryProps {
  selectedAddress: Address;
  restaurant: Restaurant;
  dispatchTime?: number;
  totalOrderPrice: number;
  setSelectedAddress: (payload: SetSelectedAddressPayload) => void;
  setDeliveryAddressField: (payload: SetDeliveryAddressFieldPayload) => void;
  errorsVisibility: Record<DeliveryFormField, boolean>;
  validateAddressReason: ValidateAddressReason;
  addressInput: string;
  setAddressInputValue: (payload: SetAddressInputValuePayload) => void;
  fieldsErrors: {
    apt: boolean;
    addressLine2: boolean;
  };
  setFieldError: (payload: SetErrorVisibilityPayload) => void;
  t: TranslationFunction;
  isMobile: boolean;
  addressInputValue: string;
  onAddressInputBlur?: (address?: Address) => void;
  onAddressInputSelect?: (address: Address) => void;
  signedInstance: string;
  showAddressLine2?: boolean;
  forceCommentInputVisibility?: boolean;
  openModal: (payload: OpenModalPayload) => void;
}

const AddressInformationDelivery: React.FC<AddressInformationDeliveryProps> = ({
  restaurant,
  selectedAddress,
  addressInput,
  setSelectedAddress,
  setDeliveryAddressField,
  validateAddressReason,
  setAddressInputValue,
  fieldsErrors,
  errorsVisibility,
  setFieldError,
  t,
  signedInstance,
  isMobile,
  addressInputValue,
  onAddressInputBlur,
  onAddressInputSelect,
  showAddressLine2,
  forceCommentInputVisibility,
  openModal,
}) => {
  return (
    <React.Fragment>
      <label className={styles.addressWrapper}>
        <Element name="address-input">
          <Text typography="p2-s" className={styles.label}>
            {t('checkout_main_delivery_address')}
          </Text>
          <AddressInput
            placeholder={t('checkout_main_address_placeholder')}
            locale={restaurant.locale}
            toolTipText={
              errorsVisibility.addressInput
                ? getValidateAddressReasonText(
                    restaurant.currency,
                    restaurant.locale,
                    t,
                    addressInputValue,
                    validateAddressReason,
                  )
                : ''
            }
            onSelect={(newAddress) => {
              setSelectedAddress({ address: newAddress });
              onAddressInputSelect && onAddressInputSelect(newAddress);
            }}
            onChange={(value) => {
              setAddressInputValue({ value });
            }}
            value={selectedAddress.formatted || addressInput}
            data-hook={dataHooks.addressInformationAddress}
            isMobile={isMobile}
            onBlur={(address) => onAddressInputBlur?.(address || selectedAddress)}
            signedInstance={signedInstance}
            t={t}
          />
        </Element>
      </label>
      <ChooseLocationDropdown
        labelText={t('order_settings_modal_choose_location_label')}
        filters="delivery"
        onChange={(id: string) => {
          openModal({
            modal: Modals.CHANGE_LOCATION_MODAL,
            context: { locationId: id },
          });
        }}
      />
      <div className={classNames([styles.inputs, showAddressLine2 && styles.fullWidth])}>
        {showAddressLine2 ? (
          <React.Fragment>
            <AddressInformationInput
              address={selectedAddress}
              field="addressLine2"
              onChange={setDeliveryAddressField}
              label={t('checkout_main_delivery_aptfloor.label')}
              data-hook={dataHooks.addressInformationLine2}
              className={styles.addressLine2}
              placeholder={t('checkout_main_deliverymethod_modal.addaddress.addinfo.placeholdertext')}
              errorMessage={
                errorsVisibility.addressLine2 && fieldsErrors.addressLine2
                  ? t('checkout_main_delivery_contactinfo_mandatoryfield_errormessage')
                  : ''
              }
            />
            <AddressInformationInput
              address={selectedAddress}
              field="label"
              onChange={setDeliveryAddressField}
              label={t('checkout_main_deliverymethod_modal.addaddress.label.title')}
              data-hook={dataHooks.addressInformationLabelField}
              placeholder={t('checkout_main_deliverymethod_modal.addaddress.label.placeholdertext')}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Element name={'apartment-input'}>
              <AddressInformationInput
                address={selectedAddress}
                field="apt"
                onChange={(payload) => {
                  setFieldError({ error: 'apt', value: false });
                  setDeliveryAddressField(payload);
                }}
                label={t('checkout_main_address_apartment')}
                data-hook={dataHooks.addressInformationApt}
                errorMessage={
                  errorsVisibility.apt && fieldsErrors.apt
                    ? t('checkout_main_delivery_contactinfo_mandatoryfield_errormessage')
                    : ''
                }
              />
            </Element>
            <AddressInformationInput
              address={selectedAddress}
              field="floor"
              onChange={setDeliveryAddressField}
              label={t('checkout_main_address_floor')}
              data-hook={dataHooks.addressInformationFloor}
            />
            <AddressInformationInput
              address={selectedAddress}
              field="entrance"
              onChange={setDeliveryAddressField}
              label={t('checkout_main_address_entrance')}
              data-hook={dataHooks.addressInformationEntrance}
            />
          </React.Fragment>
        )}
      </div>

      <label className={styles.commentWrapper}>
        <CommentInput
          onChange={(newComment: string) => setDeliveryAddressField({ addressField: 'comment', value: newComment })}
          value={selectedAddress.comment || ''}
          content={t('checkout_main_instructions_link')}
          dataHook={dataHooks.addressInformationComment}
          textClassName={styles.addressInformation_textArea}
          buttonClassName={styles.commentButton}
          forceInputVisibility={forceCommentInputVisibility}
        />
      </label>
    </React.Fragment>
  );
};

AddressInformationDelivery.displayName = 'AddressInformationDelivery';

export default AddressInformationDelivery;
