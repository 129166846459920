import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './SectionView.scss';
import dataHooks from '../../data-hooks';
import MenuItemView from '../MenuItemView';
import Text from '../../core-components/Text';
import MenuViewEmptyState from '../MenuViewEmptyState';
import { Element } from 'react-scroll';
import { useBi, translate } from 'yoshi-flow-editor-runtime';
import { DisplayableSection, MenuDisplayOptions } from '@wix/restaurants-client-logic';
import { TranslationFunction } from 'i18next';
import { HeroImage, LoadingBehaviorOptions } from 'wix-ui-tpa/HeroImage';
import { ClickOnMenuItemPayload } from '../../../../state/cart/cart.actions.types';

interface SectionViewProps {
  displayableSection: DisplayableSection;
  isMobile?: boolean;
  layout?: 'side-by-side' | 'card';
  last?: boolean;
  menuDisplayOptions?: MenuDisplayOptions;
  t: TranslationFunction;
  clickOnMenuItem: (payload: ClickOnMenuItemPayload) => void;
}

const SectionView: React.FC<SectionViewProps> = ({
  displayableSection,
  isMobile,
  layout = 'side-by-side',
  menuDisplayOptions,
  last,
  clickOnMenuItem,
  t,
}) => {
  const hasItems = displayableSection.items.length > 0;
  const image = displayableSection.image;
  const history = useHistory();
  const biLogger = useBi();
  const showSectionImage = !menuDisplayOptions?.hideSectionImages;
  const unique = Math.random();

  // Deliberately using an empty string as alt text.
  // In this particular case adding the dish title/description will not have any effect,
  // since this data is shown directly under the image, and we do not want screen reader to read it twice.
  const alt = '';

  const titleId = `section-view-title-${unique}`;

  return (
    <Element name={displayableSection.id} id={`restaurants-orders-entity-${displayableSection.id}`} tabIndex={-1}>
      {image && showSectionImage ? (
        <div data-hook={dataHooks.menusSectionImage} className={styles.imageWrapper}>
          <HeroImage
            src={image}
            width={1280}
            aspectRatio={4}
            alt={alt}
            loadingBehavior={LoadingBehaviorOptions.blur}
            fluid
          />
        </div>
      ) : null}
      <Text
        id={titleId}
        className={styles.sectionTitle}
        typography="header-xs"
        tagName="h3"
        data-hook={dataHooks.menusSectionTitle}
      >
        {displayableSection.displayableTitle}
      </Text>
      {displayableSection.displayableDescription && (
        <Text typography="p2-m" tagName="p" className={styles.description}>
          {displayableSection.displayableDescription}
        </Text>
      )}
      {!hasItems && <MenuViewEmptyState id={displayableSection.id} />}
      {hasItems && (
        <section>
          {/* Even though it might be counter intuitive, using a section
          to wrap a div with role="list" is the correct way to go, a11y-wise,
          as a section has a different semantics than a list */}
          <div
            className={`${styles.sectionGrid} ${layout === 'card' && styles.cardLayout} ${last && styles.last}`}
            role="list"
          >
            {displayableSection.items.map((displayableMenuItem) => (
              <MenuItemView
                key={displayableMenuItem.id}
                id={displayableMenuItem.id}
                title={displayableMenuItem.displayableTitle}
                description={displayableMenuItem.displayableDescription}
                minPrice={displayableMenuItem.displayableMinPrice}
                price={displayableMenuItem.displayablePrice}
                maxPrice={displayableMenuItem.displayableMaxPrice}
                labels={displayableMenuItem.labels}
                image={displayableMenuItem.image}
                menuDisplayOptions={menuDisplayOptions}
                discount={undefined}
                error={
                  displayableMenuItem.soldout
                    ? t('online_ordering_menuitem_soldout_label')
                    : !displayableMenuItem.available
                    ? t('online_ordering_menuitem_unavailable_label')
                    : undefined
                }
                onClick={() => {
                  clickOnMenuItem({ displayableMenuItem });
                  biLogger.openDishModal({ dishId: displayableMenuItem.id, origin: 'menu' });
                  history.push(`/dish/${displayableMenuItem.id}`, { originSectionId: displayableSection.id });
                }}
                isMobile={isMobile}
                className={styles.item}
                layout={layout}
                role="listitem"
                currency={displayableMenuItem.currency}
              />
            ))}
          </div>
        </section>
      )}
    </Element>
  );
};

SectionView.displayName = 'SectionView';

export default translate()(SectionView);
