import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import CartButton from './CartButton';
import { Modals } from '../../../../core/constants';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { isMinimumPriceMet, getMinOrderPriceDetails } from '@wix/restaurants-client-logic';

function mapStateToProps(state: AppState, ownProps: any) {
  const { itemCount, totalOrderPrice } = cartSummarySelector(state);
  const { minOrderPrice } = getMinOrderPriceDetails({
    totalOrderPrice,
    restaurant: state.session.restaurant,
    dispatchType: state.checkout.dispatch.type,
  });
  return {
    ...ownProps,
    dishModalOpen: !!state.session.modals.find((modal) => modal.modal === Modals.DISH_MODAL),
    itemCount,
    dispatchType: state.checkout.dispatch.type,
    isMinimumPriceMet: isMinimumPriceMet({
      totalOrderPrice,
      restaurant: state.session.restaurant,
      dispatchType: state.checkout.dispatch.type,
    }),
    totalOrderPrice,
    minOrderPrice,
  };
}

export default connect(mapStateToProps)(CartButton);
