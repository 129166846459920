import React from 'react';
import styles from './CartDiscount.scss';
import Text from '../../core-components/Text';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import dataHooks from '../../data-hooks';
import DiscountIcon from '../DiscountIcon';
import { TranslationFunction } from 'i18next';
import { translate, useBi } from 'yoshi-flow-editor-runtime';
import Dropdown from '../Dropdown';

export interface DisplayableReward {
  points: number;
  price: number;
  displayablePrice: string;
}

export interface CartDiscountProps {
  title: string;
  amount: string;
  description?: string;
  condition?: string;
  forceDescriptionVisibility?: boolean;
  index?: number;
  onRemove?: () => void;
  loyalty?: boolean;
  displayableRewards: DisplayableReward[];
  onPointsChange?: (points: number) => void;
  selectedReward?: number;
  t: TranslationFunction;
  iconUrl?: string;
}

const CartDiscount: React.FC<CartDiscountProps> = ({
  title,
  amount,
  description,
  condition,
  forceDescriptionVisibility,
  onRemove,
  index = 0,
  loyalty,
  displayableRewards = [],
  onPointsChange,
  selectedReward,
  t,
  iconUrl,
}) => {
  const [showMore, setShowMore] = React.useState(forceDescriptionVisibility || loyalty);
  const [showPointSelection, setShowPointSelection] = React.useState(forceDescriptionVisibility);

  const ref = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (showMore) {
      ref.current && ref.current.focus();
    }
  }, [showMore]);
  const biLogger = useBi();
  const isPromo = onRemove !== undefined;

  const showMoreButton = (
    <TextButton
      priority={TEXT_BUTTON_PRIORITY.primary}
      onClick={() => {
        setShowMore((b) => !b);
        biLogger.cartDiscountDetails({
          action: showMore ? 'hide' : 'show',
          type: isPromo ? 'promo' : 'discount',
        });
      }}
      className={styles.button}
      data-hook={dataHooks.cartDiscountDetails}
    >
      <Text typography="p2-s-colorless">
        {showMore ? t('cart_promo_hide_details_button') : t('cart_promo_show_details_button')}
      </Text>
    </TextButton>
  );

  const editPointsButton = (
    <TextButton
      priority={TEXT_BUTTON_PRIORITY.primary}
      onClick={() => setShowPointSelection((b) => !b)}
      className={styles.button}
      data-hook={dataHooks.cartDiscountEditPoints}
    >
      <Text typography="p2-s-colorless">{showPointSelection ? 'Done' : 'Edit'}</Text>
    </TextButton>
  );

  return (
    <div className={styles.wrapper} data-hook={`${dataHooks.cartDiscountItem}-${index}`}>
      {iconUrl ? (
        <img
          data-hook={dataHooks.cartDiscountImage}
          src={`http://static.wixstatic.com/${iconUrl}`}
          width={40}
          height={40}
          alt=""
        />
      ) : (
        <DiscountIcon />
      )}
      <div className={styles.innerWrapper}>
        <div className={styles.content}>
          <div className={styles.textContent}>
            <Text typography="p2-m" className={styles.title} data-hook={`cart-discount-${index}-title`}>
              <span tabIndex={-1} ref={ref}>
                {title}
              </span>
            </Text>
            {showMore && description && (
              <Text typography="p2-s" className={styles.description} data-hook={dataHooks.cartDiscountDescription}>
                {description}
              </Text>
            )}
            {showPointSelection && loyalty && (
              <React.Fragment>
                <Text typography="p2-s" className={styles.pointsSelectionLabel}>
                  Number of points to use
                </Text>
                <Dropdown
                  upgrade
                  data-hook={dataHooks.cartDiscountLoyaltyDropdown}
                  options={displayableRewards.map((reward) => ({
                    id: String(reward.points),
                    value: `${reward.points} = ${reward.displayablePrice}`,
                    isSelectable: true,
                  }))}
                  initialSelectedId={String(selectedReward)}
                  onChange={({ id }) => onPointsChange?.(Number(id))}
                  mobileNativeSelect
                />
              </React.Fragment>
            )}
            {condition && (
              <Text
                typography="p2-s-secondary"
                className={styles.condition}
                data-hook={dataHooks.cartDiscountCondition}
              >
                {condition}
              </Text>
            )}
          </div>

          <Text typography="p2-m" className={styles.amount} data-hook={`cart-discount-${index}-amount`}>
            {amount}
          </Text>
        </div>
        <div className={styles.buttons_wrapper}>
          {description && !loyalty && showMoreButton}
          {loyalty && editPointsButton}
          {onRemove && (
            <TextButton
              data-hook={loyalty ? dataHooks.cartRemoveLoyalty : dataHooks.cartRemovePromoCode}
              priority={TEXT_BUTTON_PRIORITY.primary}
              onClick={onRemove}
              className={styles.button}
            >
              <Text typography="p2-s-colorless">{t('cart_button_remove')}</Text>
            </TextButton>
          )}
        </div>
      </div>
    </div>
  );
};

CartDiscount.displayName = 'CartDiscount';

export default translate()(CartDiscount);
