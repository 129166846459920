const dataHooks = {
  menuView: 'restaurants.menu-view',
  menuViewWrapper: 'restaurants.menu-view-wrapper',
  menuViewEmptyState: 'restaurants.menu-view.empty-state',
  menuSectionEmptyState: (id: string) => `restaurants.menu-section.empty-state-${id}`,

  menusSubMenuTitle: 'restaurants.sub-menu-view-title',
  menusSectionTitle: 'restaurants.section-view-title',
  menusSectionImage: 'restaurants.section-view-image',

  locationHeader: 'restaurants.location-header',

  notificationBar: 'restaurants.notification-bar',
  noLocationNotificationBar: 'restaurants.no-location-notification-bar',

  itemView: (id: string) => `restaurants.menu.item-view-${id}`,
  itemViewTitle: `restaurants.menu.item-view.title`,
  itemViewDescription: `restaurants.menu.item-view.description`,
  itemViewPrice: `restaurants.menu.item-view.price`,
  itemViewImage: `restaurants.menu.item-view.image`,
  itemViewLabels: `restaurants.menu.item-view.labels`,
  itemViewLabelsIcon: `restaurants.menu.item-view.labels.icon`,

  dispatchSummary: 'restaurants.dispatch-summary',
  dispatchSummaryWrapper: 'restaurants.dispatch-summary-wrapper',
  dispatchSummaryText: 'restaurants.dispatch-summary-text',
  dispatchSummaryTooltip: 'restaurants.dispatch-summary.tooltip',
  asapTextWithoutDropdown: 'restaurants.timing-options.asap-text-without-Dropdown',
  specificTimeTextWithoutDropdown: 'restaurants.timing-options.specific-time-text-without-Dropdown',
  dispatchSummarySelectorDelivery: 'restaurants.dispatch-type-selector-delivery',
  dispatchSummarySelectorTakeout: 'restaurants.dispatch-type-selector-takeout',
  dispatchSummarySelectorDineIn: 'restaurants.dispatch-type-selector-dine-in',
  dispatchSummaryDispatchTypeOption: 'restaurants.dispatch-type-options-dropdown',
  dispatchTypeDropdownLabel: 'restaurants.dispatch-type-label-dropdown',
  dispatchSummaryTimingOption: 'restaurants.timing-options-dropdown',
  dispatchSummaryTimingDate: 'restaurants.future-order-date',
  dispatchSummaryTimingTime: 'restaurants.future-order-time',
  dispatchSummaryButtonOk: 'restaurants.modal-layout-basic-footer-ok',
  dispatchSummaryButtonCancel: 'restaurants.modal-layout-basic-footer-cancel',
  dispatchSettingWrapper: 'restaurants.dispatch-settings-wrapper',
  dispatchSettingCurbsideInstructions: 'restaurants.dispatch-settings-curbside-instructions',
  dispatchSettingCurbsideOutfitInfo: 'restaurants.dispatch-settings-curbside-additional-info',
  dispatchSettingCurbsideOutfitInfoWrapper: 'restaurants.dispatch-settings-curbside-outfit-info-wrapper',
  dispatchSettingCurbsideToggle: 'restaurants.dispatch-settings-curbside-toggle',
  dispatchSettingChooseLocationDropdown: 'restaurants.dispatch-settings-choose-location.dropdown',
  dispatchSettingDineInDescription: 'restaurants.dispatch-settings-dine.info',
  dispatchSettingDineInLabel: 'restaurants.dispatch-settings-dine.label',
  dispatchSettingDineInTableNumber: 'restaurants.dispatch-settings-dine.table-number',

  dispatchSettingsError: 'dropdown-error-tooltip',
  modalCloseButton: 'restaurants.modal-close-button',

  dishModal: 'restaurants.dish-modal.root',
  dishModalVariationLimitation: 'restaurants.dish-modal.variation-limitation',
  dishModalUnavailableDishText: 'restaurants.dish-modal.unavailable-dish-text',
  dishModalQuantity: 'restaurants.dish-modal.quantity',
  dishModalCounter: 'restaurants.dish-modal.counter',
  dishModalAddToCart: 'restaurants.dish-modal.add-to-cart',

  navigationHeader: 'restaurants.navigation-header',
  navigationHeaderDropdown: 'restaurants.navigation-header.menus-dropdown',
  navigationHeaderTabs: 'restaurants.navigation-header.sections-tabs',

  menuPickerDropdown: 'restaurants.menu-picker.menus-dropdown',

  sectionTabs: (subMenuId: string) => `restaurants.section-tabs-${subMenuId}`,

  cartTitle: 'restaurants.cart.title',
  cartEmpty: 'restaurants.cart.empty',
  cartTitleViewOrderButton: 'restaurants.cart.title.view-order-button',
  cartDishItem: (index?: number) => `restaurants.cart.cart-dish-item-wrapper-${typeof index === 'number' ? index : ''}`,
  cartDishItemTitle: 'restaurants.cart.cart-dish-item-title',
  cartDishItemSpecialRequest: 'restaurants.cart.cart-dish-item-special-request',
  cartDishItemPrice: 'restaurants.cart.cart-dish-item-price',
  cartDishItemAmount: 'restaurants.cart.cart-dish-item-amount',
  cartDishItemEdit: 'restaurants.cart.cart-dish-item-edit',
  cartDishItemError: 'restaurants.cart.cart-item-error',
  cartDishItemRemove: 'restaurants.cart.cart-item-remove',

  cartSubtotalSubtotal: 'restaurants.cart.subtotal.subtotal-price',
  cartSubtotalMinOrder: 'restaurants.cart.subtotal.min-order',
  cartSubtotalTax: 'restaurants.cart.subtotal.tax-price',
  cartSubtotalDeliveryFee: 'restaurants.cart.subtotal.delivery-fee-price',
  cartSubtotalDeliveryFeeFromText: 'restaurants.cart.subtotal.delivery-fee-from-text',
  cartSubtotalTip: 'restaurants.cart.subtotal.tip-price',
  cartSubtotalTotal: 'restaurants.cart.subtotal.total-price',

  cartOrderNotes: 'restaurants.cart.cart-special-requests',
  cartOrderNotesButton: 'restaurants.cart.cart-special-requests-button',
  cartOrderNotesTextArea: 'restaurants.cart.cart-special-requests-text-area',

  cartVariationChoice: 'restaurants.cart.variation-choice',
  cartVariationChoiceTitle: 'restaurants.cart.variation-choice-title',
  cartVariationChoicePrice: 'restaurants.cart.variation-choice-price',
  cartPromoCodeForm: 'restaurants.cart-promo-code-form',
  cartPromoCodeInput: 'restaurants.cart-promo-code',
  cartMinOrderPriceErrorBanner: 'restaurants.cart-minimum-price-error-banner',
  cartAddPromoCode: 'restaurants.add-cart-promo-code',
  cartAddLoyalty: 'restaurants.add-cart-loyalty',
  cartSubmitPromoCode: 'restaurants.submit-cart-promo-code',
  cartRemovePromoCode: 'restaurants.remove-cart-promo-code',
  cartRemoveLoyalty: 'restaurants.remove-cart-loyalty',
  cartDiscounts: 'restaurants.cart-discounts-container',
  cartDiscountItem: 'restaurants.cart-discount-line',
  cartDiscountDescription: 'restaurants.cart-discount-description',
  cartDiscountCondition: 'restaurants.cart-discount-condition',
  cartDiscountDetails: 'restaurants.cart-discount-details',
  cartDiscountEditPoints: 'restaurants.cart-discount.edit-points-button',
  cartDiscountLoyaltyDropdown: 'restaurants.cart-discount.loyalty-dropdown',
  cartDiscountImage: 'restaurants.cart-discount.image',

  cartGoToCheckoutButton: 'restaurants.cart-go-to-checkout-button',

  variationView: (clientSideId: string) => `restaurants.variation-view.container.${clientSideId}`,
  variationViewTitle: 'restaurants.variation-view.title',
  variationViewError: 'restaurants.variation-view.error',
  variationViewChoice: (clientSideId: string) => `restaurants.variation-view-choice.${clientSideId}`,

  checkoutFlow: 'restaurants.checkout-flow',
  checkoutFlowBackToMenu: 'restaurants.checkout-flow.back-to-menu',

  checkoutFlowCartWrapper: 'restaurants.checkout-flow.cart-wrapper',

  cashierWidget: 'restaurants.checkout.cashier-widget',

  cartModal: 'restaurants.cart-modal',
  openCartButton: 'restaurants.open-cart-button',
  closeCartButton: 'restaurants.close-cart-button',

  cartButtonItemCount: 'restaurants.cart-button.item-count',

  contactInformation: 'contact-information',
  checkoutFlowFirstNameInput: 'checkout-flow-first-name-input',
  checkoutFlowLastNameInput: 'checkout-flow-last-name-input',
  checkoutFlowEmailInput: 'checkout-flow-email-input',
  checkoutFlowPhoneInput: 'checkout-flow-phone-input',
  contactInformationContinue: 'restaurants.contact-information.continue',
  contactInformationForm: 'restaurants.contact-information.form',
  contactInformationSummaryLine: 'restaurants.contact-information.summary-line',

  addressInformation: 'restaurants.address-information',
  addressInformationInputErrorIcon: 'restaurants.address-information.inputErrorIcon',
  addressInformationInputSuccessIcon: 'restaurants.address-information.inputSuccessIcon',
  addressInformationInputErrorText: 'restaurants.address-information.inputErrorText',
  addressInformationInputClear: 'restaurants.address-information.inputClear',
  addressInformationAddress: 'restaurants.address-information.address',
  addressInformationApt: 'restaurants.address-information.apt',
  addressInformationFloor: 'restaurants.address-information.floor',
  addressInformationLine2: 'restaurants.address-information.address-line-2',
  addressInformationLabelField: 'restaurants.address-information.address-label',
  addressInformationEntrance: 'restaurants.address-information.entrance',
  addressInformationComment: 'restaurants.address-information.comment',
  addressInformationCommentButton: 'restaurants.address-information.comment-button',
  addressInformationCommentTextArea: 'restaurants.address-information.comment-text-area',
  addressInformationContinue: 'restaurants.address-information.continue',
  addressInformationForm: 'restaurants.address-information.form',
  addressInformationSummaryLine: 'restaurants.address-information.summary-line',
  addressInformationTakeoutAddress: 'restaurants.address-information.takeout-address',
  addressInformationNotificationBar: 'restaurants.address-information.notificationBar',
  addressInformationTimeLine: 'restaurants.address-information.time',
  addressInformationError: 'restaurants.address-information.error',
  addressInformationErrorLink: 'restaurants.address-information.error-link',
  addressInformationSpinner: 'restaurants.address-information.spinner',
  addressInformationSaveAddressCheckbox: 'restaurants.address-information.save-address-checkbox',
  addressInformationSaveContactCheckbox: 'restaurants.address-information.save-contact-checkbox',
  addressInformationChooseLocationDropdown: 'restaurants.address-information.choose-location-dropdown',
  addressInformationMinOrderPriceErrorBanner: 'restaurants.address-information.min-order-price-error-banner',
  addressInformationMinOrderPrice: 'restaurants.address-information.min-order-price',
  addressInformationMinOrderPriceErrorBannerLink:
    'restaurants.address-information.min-order-price-information-banner-link',
  checkoutPayments: 'restaurants.checkout-payments',

  checkoutPaymentsContinue: 'restaurants.checkout-payments.continue',
  checkoutPaymentsSummaryLine: 'restaurants.checkout-payments.summary-line',

  tipPicker: 'tip-picker',
  tipPickerNone: 'tip-picker.none',
  tipPickerFivePercent: 'tip-picker.five-percent',
  tipPickerTenPercent: 'tip-picker.ten-percent',
  tipPickerFifteenPercent: 'tip-picker.fifteen-percent',
  tipPickerOther: 'tip-picker.other',
  tipPickerCurrencyInput: 'tip-picker.currency-input',
  tipPickerPercentageButton: 'tip-picker.percentage-button',
  tipPickerCurrencyButton: 'tip-picker.amount-button',

  checkoutSummaryLineEditAddress: 'restaurants.address-information.summary-line-edit',
  checkoutSummaryLineEditContact: 'restaurants.contact-information.summary-line-edit',
  checkoutSummaryLineEditPayment: 'restaurants.payments.summary-line-edit',
  checkoutEditableStep: 'restaurants.step-edit',
  checkoutSuccessStep: 'restaurants.step-success',

  thankYouPage: 'restaurants.thank-you-page',
  thankYouPageTitle: 'thank-you-page-title',
  thankYouPageSubtitle: 'thank-you-page-subtitle',
  thankYouPagePaymentIcon: 'thank-you-page-payment-icon',
  thankYouPagePaymentMethod: 'thank-you-page-payment-method',
  thankYouPageOrderId: 'thank-you-page-order-id',
  thankYouPageDeliveryTime: 'thank-you-page-delivery-time',
  thankYouPageDineIn: 'thank-you-page-dine-in',
  thankYouPageDeliveryAddress: 'thank-you-page-delivery-address',
  thankYouPagePrice: 'thank-you-page-price',
  thankYouRestaurantAddress: 'thank-you-page-restaurant-address',
  thankYouPageSubmittedAt: 'thank-you-page-submitted-at',
  thankYouPageHomeButton: 'thank-you-page-home-button',
  thankYouPagePhoneNumber: 'thank-you-page-phone-number',

  checkoutReview: 'restaurants.checkout-review',
  checkoutReviewSubmitOrder: 'submit-order-button',
  checkoutReviewContactlessToggle: 'restaurants.checkout-review.contactless-toggle',

  policyView: 'restaurants.checkout.policy-view',
  policyViewCheckboxWrapper: 'checkbox-wrapper' /* for checkbox TPA testkit - can't be with restaurants prefix */,
  policyViewCheckbox: 'restaurants.checkout.policy-view.checkbox',
  policyViewTermsAndConditionsButton: 'restaurants.checkout.policy-view.terms-and-conditions-button',
  policyViewPrivacyPolicyButton: 'restaurants.checkout.policy-view.privacy-policy-button',
  checkoutReviewPolicyMustCheckText: 'restaurants.checkout.policy-view.must-checked',

  termsAndConditionsModal: 'restaurants.modals.terms-and-conditions',
  termsAndConditionsModalOkButton: 'restaurants.modals.terms-and-conditions.ok-button',
  privacyPolicyModal: 'restaurants.modals.privacy-policy',
  privacyPolicyModalOkButton: 'restaurants.modals.privacy-policy.ok-button',

  map: 'restaurants.map',

  buttonSpinner: 'restaurants.button-spinner',

  smsModal: 'restaurants.sms-modal',
  smsModalInput: 'restaurants.sms-modal.input',
  smsModalInputConfirm: 'restaurants.sms-modal.confirm-button',
  smsModalInputCancel: 'restaurants.sms-modal.cancel-button',

  orderFailureModal: 'restaurants.order-failure-modal',
  orderFailureModalTitle: 'restaurants.order-failure-modal-title',
  orderFailureModalSubtitle: 'restaurants.order-failure-modal-subtitle',
  orderFailureModalOkButton: 'restaurants.order-failure-modal-ok-button',
  orderFailureModalFooter: 'restaurants.order-failure-modal-footer',

  labelsSettingsThumbnail: (option: string) => `restaurants.settings.labels.thumbnail-option.${option}`,
  labelsSettingsCustomizeIconThumbnail: (option: string) =>
    `restaurants.settings.labels.customize-icon.thumbnail-option.${option}`,
  labelsSettingsBackButton: 'restaurants.settings.labels.back-button',
  labelsSettingsCustomizeButton: 'restaurants.settings.labels.customize-button',
  labelsSettingsOpenLabelsTabButton: 'restaurants.settings.labels.open-labels-tab-button',
  labelsSettingsCustomizeColorPickerPrimary: 'restaurants.settings.labels.customize.color-picker.primary',
  labelsSettingsCustomizeColorPickerSecondary: 'restaurants.settings.labels.customize.color-picker.secondary',

  connectPaymentModal: 'restaurants.connect-payment-modal',
  upgradeToPremiumModal: 'restaurants.upgrade-to-premium-modal',

  savedAddressLabelField: 'restaurants.saved-address-view.label-field',
  savedAddressFormatted: 'restaurants.saved-address-view.formatted',
  savedAddressLine2: 'restaurants.saved-address-view.line2',
  savedAddressComment: 'restaurants.saved-address-view.comment',
  savedAddressError: 'restaurants.saved-address-view.error',
  savedAddressChange: 'restaurants.saved-address-view.change',

  addressSelectionModal: 'restaurants.address-selection-modal',
  addressSelectionModalOption: 'restaurants.address-selection-modal.option',
  addressSelectionModalOptionLabel: 'restaurants.address-selection-modal.option-label',
  addressSelectionModalOptionError: 'restaurants.address-selection-modal.option-error',
  addressSelectionModalOptionEdit: 'restaurants.address-selection-modal.option-edit',
  addressSelectionModalSetAsDefault: 'restaurants.address-selection-modal.set-as-default-checkbox',

  modalSecondaryButton: 'restaurants.modal.secondary-button',

  memberCTALogin: 'restaurants.checkout-flow.member-login',
  memberCTAEmail: 'restaurants.checkout-flow.member-email',
  memberCTASection: 'restaurants.checkout-flow.member-section-CTA',
  memberCTALogout: 'restaurants.checkout-flow.member-logout',
  memberLogoutModal: 'restaurants.logout-modal',
  memberLogoutModalTitle: 'restaurants.logout-modal-title',
  memberLogoutModalSubtitle: 'restaurants.logout-modal-subtitle',

  changeLocationModal: 'restaurants.modal.change-location-modal',
  changeLocationModalTitle: 'restaurants.modal.change-location-modal.title',
  changeLocationModalOK: 'restaurants.modal.change-location-modal.button-ok',
  changeLocationModalCancel: 'restaurants.modal.change-location-modal.button-cancel',

  chooseLocationDropdown: 'restaurants.chooseLocationDropdown',
  chooseLocation: 'restaurants.chooseLocation.wrapper',
  chooseLocationAddress: 'restaurants.chooseLocation.singleAddress',
};

export default dataHooks;
