import React from 'react';
import { extractLocalizedString, LocalizedString } from '@wix/restaurants-client-logic';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';

import styles from './LocationHeader.scss';

interface Props {
  name?: LocalizedString;
  locale: string;
  address: string;
}
const LocationHeader: React.FC<Props> = ({ address, name, locale }) => {
  return (
    <div data-hook={dataHooks.locationHeader} className={styles.wrapper}>
      {name && (
        <Text tagName="h2" typography="header-l">
          {extractLocalizedString(name, locale)}
        </Text>
      )}
      <Text className={styles.subHeader} tagName="p" typography="p2-s">
        {address}
      </Text>
    </div>
  );
};
export default LocationHeader;
