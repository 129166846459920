import React from 'react';
import { translate, useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import moment from 'moment';
import 'moment/min/locales.min';
import dataHooks from '../../data-hooks';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import styles from './DispatchSummary.scss';
import { RouteUrls } from '../../../../core/constants';
import Text from '../../core-components/Text';
import { Address, DispatchInfo, VirtualDispatchType } from '@wix/restaurants-client-logic';
import { useHistory } from 'react-router-dom';
import { getAsapText } from '../DispatchTimeSelector/DispatchTimeSelector.helper';
import { TooltipSkin } from 'wix-ui-tpa/dist/src/components/Tooltip/TooltipEnums';

export interface DispatchSummaryProps {
  dispatchType: VirtualDispatchType;
  isCurbside?: boolean;
  dispatchTime?: number;
  timezone: string;
  address?: Address;
  deliveryInfos: DispatchInfo[];
  t: TranslationFunction;
  idealDeliveryArea?: DispatchInfo;
  disabled?: boolean;
  locale: string;
  tableLabel?: string;
  tableNumber?: string;
  isLocationPicked: boolean;
}

function getText(
  t: TranslationFunction,
  dispatchType: VirtualDispatchType,
  timezone: string,
  locale: string,
  deliveryInfos: DispatchInfo[],
  dispatchTime?: number,
  idealDeliveryArea?: DispatchInfo,
  isCurbside?: boolean,
  tableLabel?: string,
  tableNumber?: string,
) {
  const timing = dispatchTime
    ? moment(dispatchTime).tz(timezone).locale(locale).format('MMM D, h:mm A')
    : getAsapText({ deliveryInfos, timezone, locale, dispatchType, t, idealDeliveryArea });

  switch (dispatchType) {
    case 'delivery':
      return `${t('checkout_button_delivery')}, ${timing}`;
    case 'dine-in':
      return t('mobile_ordering_deliveryoption_DineIn_Label', { InputLabel: tableLabel, number: tableNumber });
    case 'takeout':
      return isCurbside
        ? `${t('online_ordering_deliveryoption_Curbside_Label')}, ${timing}`
        : `${t('checkout_button_pickup')}, ${timing}`;
    default:
      return `${t('checkout_button_pickup')}, ${timing}`;
  }
}

function getTakeoutText({
  t,
  isCurbside,
  isDineIn,
  tableLabel,
  tableNumber,
  timing,
}: {
  t: TranslationFunction;
  isCurbside?: boolean;
  isDineIn?: boolean;
  tableLabel?: string;
  tableNumber?: string;
  timing?: string;
}) {
  if (isCurbside) {
    return `${t('online_ordering_deliveryoption_Curbside_Label')}, ${timing}`;
  }
  if (isDineIn) {
    return t('mobile_ordering_deliveryoption_DineIn_Label', { InputLabel: tableLabel, number: tableNumber });
  }
  return `${t('checkout_button_pickup')}, ${timing}`;
}

const DispatchSummary: React.FC<DispatchSummaryProps> = ({
  dispatchType,
  dispatchTime,
  timezone,
  deliveryInfos,
  locale,
  t,
  idealDeliveryArea,
  disabled,
  isCurbside,
  tableLabel,
  tableNumber,
  isLocationPicked,
}) => {
  const experiments = useExperiments();
  const biLogger = useBi();
  const history = useHistory();

  const isMLEnabled = experiments.experiments.enabled('specs.restaurants.olo-client-ml');

  const handleClick = (e: React.SyntheticEvent) => {
    if (!disabled) {
      e.stopPropagation();
      biLogger.openDispatchSettings({});
      history.push(RouteUrls.DISPATCH_SETTINGS_MODAL);
    }
  };

  const showTooltip = isMLEnabled && !isLocationPicked;
  const Wrapper = showTooltip
    ? ({ children }: { children: React.ReactNode }) => (
        <Tooltip
          hideDelay={0}
          disabled={!isMLEnabled || isLocationPicked}
          data-hook={dataHooks.dispatchSummaryTooltip}
          shown={showTooltip}
          skin={TooltipSkin.Wired}
          content={t('main_page_delivery_address_tooltip')}
          placement="bottom"
          moveBy={{ y: 10, x: 0 }}
        >
          {children}
        </Tooltip>
      )
    : React.Fragment;

  return (
    <div className={styles.layoutWrapper}>
      <Wrapper>
        <div
          onClick={handleClick}
          data-hook={dataHooks.dispatchSummaryWrapper}
          className={`${styles.wrapper} ${disabled && styles.disabled}`}
        >
          <Text typography="p2-m" data-hook={dataHooks.dispatchSummaryText} className={styles.dispatchSummaryText}>
            {getText(
              t,
              dispatchType,
              timezone,
              locale,
              deliveryInfos,
              dispatchTime,
              idealDeliveryArea,
              isCurbside,
              tableLabel,
              tableNumber,
            )}
          </Text>
          <TextButton
            priority={TEXT_BUTTON_PRIORITY.primary}
            onClick={handleClick}
            data-hook={dataHooks.dispatchSummary}
            className={styles.button}
            disabled={disabled}
          >
            <Text typography="p2-m-colorless">{t('online_ordering_delivery_time_change')}</Text>
          </TextButton>
        </div>
      </Wrapper>
    </div>
  );
};

DispatchSummary.displayName = 'DispatchSummary';

export default translate()(DispatchSummary);
