import React from 'react';
import Text from '../../../core-components/Text';
import styles from '../AddressInformation.scss';
import dataHooks from '../../../data-hooks';
import { TranslationFunction } from 'i18next';
import { useExperiments } from 'yoshi-flow-editor-runtime';
import { Address } from '@wix/restaurants-client-logic';
import ChooseLocationDropdown from '../../ChooseLocationDropdown';
import classNames from 'classnames';

interface AddressInformationTakeoutProps {
  formattedAddressWithComment: string;
  address: Address;
  t: TranslationFunction;
  onLocationChange: (id: string) => void;
  className: boolean;
}

const RestaurantTakeoutDetails: React.FC<AddressInformationTakeoutProps> = ({
  formattedAddressWithComment,
  t,
  onLocationChange,
  className,
}) => {
  const { experiments } = useExperiments();
  const isMLEnabled = experiments.enabled('specs.restaurants.olo-client-ml');

  if (isMLEnabled) {
    return (
      <ChooseLocationDropdown
        labelText={t('order_settings_modal_pickup_location_label')}
        filters="takeout"
        onChange={onLocationChange}
      />
    );
  } else {
    return (
      <React.Fragment>
        <Text typography="p2-s" className={styles.restaurantAddressLabel}>
          {t('order_settings_modal_pickup_label')}
        </Text>
        <Text
          typography="p2-m"
          data-hook={dataHooks.addressInformationTakeoutAddress}
          className={classNames({
            [styles.restaurantAddress]: true,
            [styles.restaurantAddressMin]: className,
          })}
        >
          {formattedAddressWithComment}
        </Text>
      </React.Fragment>
    );
  }
};

RestaurantTakeoutDetails.displayName = 'RestaurantTakeoutDetails';

export default RestaurantTakeoutDetails;
