import { connect } from 'react-redux';
import { translate } from 'yoshi-flow-editor-runtime';
import { AppState } from '../../../../state/createStore';
import { DineInDetails } from './DineInDetails';
import { PickupDispatch } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { PickupDispatchInfo, Restaurant } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import { getDineInInfo } from '../../../../core/logic/dispatchLogic';

function mapStateToProps(state: AppState, ownProps: any) {
  const dineInInfo = getDineInInfo(state.session.restaurant);
  return {
    ...ownProps,
    description: dineInInfo ? dineInInfo.instructions : '',
    label: dineInInfo ? dineInInfo.label : '',
  };
}

export default translate()(connect(mapStateToProps)(DineInDetails));
