import ChangeLocationModal from './ChangeLocationModal';
import { AppState } from '../../../../state/createStore';
import { changeLocation } from '../../../../state/session/session.actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState, ...ownProps: any) {
  return {
    isLoadingLocationFromServer: state.session.isLoadingLocationFromServer,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      changeLocation,
    },
    dispatch,
  );
}
export default translate()(connect(mapStateToProps, mapDispatchToProps)(ChangeLocationModal));
